
import { defineComponent, ref, Ref } from 'vue';
import router from '@/router';
import {
    listPagination,
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';

const headers: Array<ListHeaderItem> = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'Owner',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'Install',
    label: WordList.TabelUserInHtmlRoleInstall
}, {
    name: 'Manage',
    label: WordList.ProperAllTextRoleDistributor
}, {
    name: 'Device Type',
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
    type: 'customize'
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'Binding Time',
    label: WordList.PersonaldeviceListTanleBindingTime
}];

const listRequestData: Ref<RequestData> = ref({
    url: 'v3/web/single/device/getList',
    param: {
        searchKey: 'MAC',
        searchValue: ''
    }
});

const searchKeyList = [{
    label: WordList.TabelPersonDeviceInHtmlMAC,
    name: 'MAC'
}, {
    label: WordList.TabelPersonDeviceInHtmlLocation,
    name: 'Location'
}, {
    label: WordList.TabelUserInHtmlRoleInstall,
    name: 'Install'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'Sip'
}, {
    label: WordList.ProperAllTextRoleDistributor,
    name: 'Manage'
}];

export default defineComponent({
    components: {
        listPagination,
        deviceIcon,
        deviceStatus
    },
    setup() {
        const updateToList = ref(0);
        const goToDeviceInfo = (item: object) => {
            localStorage.setItem('detail', JSON.stringify(item));
            router.push('/superIndex/personalDeviceDetail');
        };

        return {
            headers,
            listRequestData,
            searchKeyList,
            updateToList,
            goToDeviceInfo
        };
    }
});
